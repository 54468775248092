$fontFamily: "Open Sans", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
$black: #1a1a1a;

/* Calendar re-styling */

$primary: #88bb3c;

.rbc-date-cell a {
	font-weight: 600;
	min-width: 28px;
	min-height: 28px;
	border-radius: 28px;
	display: flex;
	float: right;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	padding: 2px;
}
.rbc-off-range a {
	color: #babbba;
}
.rbc-current a {
	background-color: #8080808f;
	color: white;
	box-shadow: #00000029 0px 0px 3px;
	color: #f2f2f273;
}
.rbc-now a {
	background-color: $primary;
	color: white;
	box-shadow: #00000029 0px 0px 3px;
}
.rbc-today {
	background-color: white;
}
.rbc-off-range-bg {
	background-color: white;
}

// dim sunday and saturday columns
.rbc-row-bg .rbc-day-bg:first-child,
.rbc-row-bg .rbc-day-bg:last-child {
	background-color: #f2f2f2;
}
.rbc-row .rbc-date-cell:first-child,
.rbc-row .rbc-date-cell:last-child {
	color: #babbba;
}

.rbc-allday-cell .rbc-row-bg .rbc-day-bg {
	background-color: white;
}

.rbc-allday-cell {
	display: none;
}

.rbc-header {
	font-weight: 600;
	font-size: 16px;
	border-left: unset !important;
}
.rbc-month-view {
	border-top-style: unset;
	border-left-style: unset;
	border-right-style: unset;
}
.rbc-toolbar {
	padding: 20px;
}
.rbc-toolbar-label {
	font-weight: 600;
	font-size: 22px;
}

.rbc-agenda-view {
	padding: 20px;
}
.rbc-agenda-event-cell {
	padding: 10px !important;
	vertical-align: middle !important;
	white-space: break-spaces;
}
.rbc-agenda-time-cell {
	padding: 10px !important;
	vertical-align: middle !important;
	border: 1px solid #ddd;
}
.rbc-agenda-date-cell {
	padding: 10px !important;
	vertical-align: middle !important;
}

.rbc-row-segment .rbc-event {
	margin: 0;
}
.rbc-day-slot .rbc-event,
.rbc-event {
	padding: 0px;
	width: unset;
	border-radius: 3px;
	border: 1px solid $primary;
	background-color: white;
	box-shadow: #00000029 0px 0px 3px;
	color: #1a1a1a;
	font-weight: 600;
	font-size: 12px;
	transition: all ease 0.3s;

	&:hover {
		padding-bottom: 1rem;
		background-color: #a7e056;
		min-height: 60px;
		z-index: 1;
	}
	&:focus {
		outline: 5px auto $primary;
	}

	.rbc-event-content {
		padding: 2px 6px;
		min-height: unset;
	}
}
.rbc-event-content {
	flex: unset !important;
	text-overflow: unset !important;
	white-space: break-spaces !important;
	padding: 2px 6px;
}
.rbc-event-label {
	display: none !important;
}

.rbc-events-container {
	margin-right: unset !important;
}

.rbc-day-slot .rbc-event {
	border: 1px solid $primary;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Time Picker */
.rc-time-picker-input,
.rc-time-picker-panel-select,
.rc-time-picker-panel-input {
	font-size: 16px;
	font-weight: normal;
	font-family: $fontFamily;
}
.rc-time-picker-input {
	height: unset;
	color: $black;
	border-bottom: unset;
	border-top: unset;
	border-left: unset;
	border-right: unset;
	border-radius: unset;
	padding: 11px 14px 10px;
}
.rc-time-picker-panel-input::placeholder,
.rc-time-picker-input::placeholder {
	color: #bebebe;
}

/* Tabs restyling */
#tabs-ndis-plan-details-tab-1[aria-selected="false"],
#tabs-ndis-plan-details-tab-2[aria-selected="false"],
#tabs-supervisor-dashboard-tab-my-dashboard[aria-selected="false"],
#tabs-supervisor-dashboard-tab-team-dashboard[aria-selected="false"] {
	background: rgb(234, 235, 240) none repeat scroll 0% 0%;

	&:hover {
		background: rgb(248, 249, 253) none repeat scroll 0% 0%;
	}
}

@media print {
	#safety-plan-print {
		-webkit-print-color-adjust: exact !important;

		// hide scroll bar on print
		::-webkit-scrollbar {
			width: 0px;
		}
	}

	@page {
		size: auto;
		margin: 2mm 5mm 5mm 5mm;
	}
}

.customCheck > input:checked {
	filter: hue-rotate(255deg) brightness(117%);
	width: 15px;
	height: 15px;
}

.customCheck > input {
	width: 15px;
	height: 15px;
}

// Sentry Dialog
.sentry-error-embed .form-submit .btn {
	background-color: #88bb3c !important;
	border: white !important;
}

// Define dark mode colors with a true black background// Define dark mode colors with true black background
// Define dark mode colors with true black background
$darkBackground: #000000; // Pure black for main background
$cardBackground: #1a1a1a; // Dark grey for cards and panels
$lightText: #e5e5e5; // Light text color for readability
$highlight: #88bb3c; // Accent green for buttons and highlights
$borderColorDark: #333333; // Border color for elements
$hoverBackground: #333333; // Hover background color for buttons and cards

body.dark-mode {
  background-color: $darkBackground !important;
  color: $lightText;
  font-family: $fontFamily;

  // Sidebar styling
  .sidebar {
    background-color: $darkBackground !important; // Pure black for sidebar
    color: $lightText;
  }

  // Header and Top Bar styling
  .topbar, .header, .navbar, .top-header {
    background-color: $darkBackground !important;
    color: $lightText;
  }

  // Main content area
  .main-content, .container, .content-area, .dashboard, .clients-page, .portal-content {
    background-color: $darkBackground !important; // Set main content to true black
    color: $lightText;
  }

  // Card and panel styling for contrast
  .card, .panel, .table-container, .data-list, .content-wrapper {
    background-color: $cardBackground !important; // Dark grey for cards and panels
    color: $lightText;
    border: 1px solid $borderColorDark;
    border-radius: 5px; // Rounded corners for a modern look
  }

  // Calendar adjustments
  .rbc-date-cell a, .rbc-now a, .rbc-current a {
    color: $highlight;
  }

  .rbc-off-range a {
    color: #888888;
  }

  .rbc-today,
  .rbc-off-range-bg,
  .rbc-allday-cell .rbc-row-bg .rbc-day-bg {
    background-color: $darkBackground !important;
  }

  .rbc-row-bg .rbc-day-bg:first-child,
  .rbc-row-bg .rbc-day-bg:last-child {
    background-color: $cardBackground !important;
  }

  // Toolbar and labels
  .rbc-toolbar, .rbc-toolbar-label, .rbc-header {
    color: $lightText;
    font-weight: 600;
  }

  // Event adjustments
  .rbc-event {
    background-color: $cardBackground !important;
    color: $lightText;
    border: 1px solid $borderColorDark;
  }

  .rbc-event:hover {
    background-color: $hoverBackground !important;
  }

  // Button styling with green accents
  .button, .btn, .new-client-button {
    background-color: $highlight !important;
    color: white;
    &:hover {
      background-color: darken($highlight, 10%) !important;
    }
  }

  // Time picker adjustments
  .rc-time-picker-input {
    color: $lightText;
    background-color: $cardBackground !important;
    border: 1px solid $borderColorDark;
    &::placeholder {
      color: #888888;
    }
  }

  // Adjust placeholder or text color for "No results"
  .no-results {
    color: #888888;
  }

  // Adjust accent green color for specific elements
  .accent-green {
    color: $highlight !important;
  }
}
